export default {
  button: "Pomoc",
  collection_banners: "Co jsou Bannery sekcí:",
  highlighted_products: "Co jsou Bannery sekcí:",
  product_creation: "Jak vytvořit Produkt a Kolekce:",
  menu_items: "Jak upravit menu Vašeho eshopu:",
  important_message_bar: "Co je Lišta důležitých sdělení:",
  popular_products: "Co jsou Oblíbené produkty:",
  special_message_banner: "Co je Banner s mimořádnou zprávou:",
  footer_content: "Jak upravit obsah patičky Vašeho eshopu:",
  story_banner: "Co je Příběhový banner:",
  style_config: "Jak upravit barvy Vašeho eshopu:",
}
