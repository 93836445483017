export default {
  uploading_collection_image: "Právě nahráváme Váš obrázek kolekce, bude to jen vteřinka!",
  uploading_product_images: "Právě nahráváme Vaše obrázky produktů, bude to jen vteřinka!",
  uploading_products: "Nyní ukládáme Vaše produkty 🚀  Bude to jen vteřinka!",
  uploading_content_images: "Právě nahráváme Vaše obrázky, bude to jen vteřinka!",
  uploading_content: "Nyní ukládáme nový obsah Vašeho online obchodu 🚀 ",
  creating_store: "Začali jsme stavět Váš online obchod 🏗️",
  uploading_store_images: "Začali jsme stavět Váš online obchod 🏗️",
  finalizing_store_creation: "Nyní už jen dokončujeme detaily! 🏠",
}
