export default {
  manage_users: "Spravovat uživatelské účty",
  settings: "Nastavení uživatele",
  log_out: "Odhlásit se",
  profile: "Můj profil",
  user_actions: "Uživatelské možnosti",
  user_data: "Uživatelská data",
  change_password: "Změnit heslo",
  update_email: "Změnit email",
  users_under_management: "Spravované uživatelské účty",
  add_user: "Přidat nového uživatele",
  edit_user: "Upravit uživatelská oprávnění a spravované účty",
  user_permissions: "Uživatelská oprávnění",
  update_your_information: "Aktualizujte své informace",
  delete_user: "Smazat uživatele",
  switch_project: "Změnit obchod",
  support: "Podpora",
  send_message: "Pošlete nám zprávu",
  finalize_profile_setup: "Dokončete nastavení profilu",
  finalize_profile_message: "Dokončete prosím nastavení Vašeho profilu doplněním pár důležitých informací.",
  show_guide: "Otevřít průvodce nastavením obchodu",
  no_users_created_yet: "Zatím nebyli nevytvořeni žádní uživatelé.",
  
  messages: {
    email_updated: "Vaše emailová adresa byla úspěšně změněna.",
    password_changed: "Vaše heslo bylo úspěšně změněno.",
    passwords_unmatched: "Hesla se neshodují.",
    invalid_email: "Toto není validní emailová adresa.",
    users_over_limit: `Pro tento projekt nemůžete přidat více než {user limit} uživatelů.
    Upgradujte svůj plán a povolte více uživatelských účtů nebo kontaktujte podporu.`,
    new_user_created: "Nový uživatel byl úspěšně vytvořen a na jeho e-mailovou adresu byl zaslán e-mail s žádostí o změnu hesla.",
    user_updated: "Uživatelská oprávnění a spravované účty byly úspěšně aktualizovány.",
    user_welcome: "Vítejte {name}!",
    user_info_updated: "Vaše uživatelské informace byly úspěšně aktualizovány.",
    fill_out_value: "Musíte vyplnit Vaše {input}.",
    confirm_delete: "Jste si jisti že chcete smazat účet uživatele: {email} ?",
    user_deleted: "Uživatel úspěšně smazán.",
    user_added_to_project: "Uživatel byl úspěšně přidán do vašeho projektu a nyní je pod vaší správou.",
    message_send: "Vaše zpráva byla úspěšně odeslána. Pokusíme se odpovědět co nejdříve.",
    mobile_warning_message: "Abychom Vám poskytli nejlepší možný uživatelský zážitek s Ulsemo administrací, použijte <b>prosím tablet, notebook nebo stolní počítač.</b>",
    same_email_error: "Tato emailová adresa je stejná jako Vaše.",
  },

  inputs: {
    email: "Email",
    password: "Heslo",
    name: "Jméno",
    password_again: "Heslo znovu",
    country: "Země bydliště",
    language: "Preferovaný jazyk",
    permissions: "Uživatelská oprávnění",
    user_management: "Jaké účty může uživatel spravovat",
    phone: "Telefon",
    message: "Zpráva",
    currency: "Preferovaná měna"
  },

  labels: {
    name: "Uživatelské jméno",
    email: "Email",
  },

  permissions: {
    admin: "Udělit práva Administrátora",
    products: "Sekce produktů",
    layout_and_content: "Sekce rozložení a obsah Vašeho obchodu",
    configuration: "Sekce konfigurace Vašeho obchodu",
    finance: "Finanční sekce",
    user: "Uživatelská sekce",
    translations: "Překlady obchodu",
    styling: "Styly obchodu",
    shopify: "Shopify konfigurace",
    shipping: "Nastavení dopravy a adresy",
    payment_gateway: "Konfigurace platebních možností",
    project_configuration: "Konfigurace obchodu",
    other_configuration: "Ostatní nastaveni",
    orders: "Sekce objednávek",
    discounts: "Sekce slevových kupónů",
    social: "Sekce správy sociálních medií",
    accounts: "Účty na sociálních mediích",
    permissions: "Sekce správy uživatelů",
    settings: "Sekce nastavení uživatele",
    module: "Může vidět sekci",
    manage: "Může konfigurovat sekci",
    email: "Může měnit vlastní email",
    password: "Může měnit vlastní heslo",
    posts: "Příspěvky na sociálních sítích"
  },

  support_subjects: {
    title: "Předmět",
    feature_request: "Požadavek na novou funkcionalitu",
    bug_report: "Nahlášení problému",
    general_question: "Obecný dotaz"
  },

  support_errors: {
    subject_missing: "Prosím vyberte předmět emailu.",
    empty_message: "Zpráva nemůže být prázdná.",
    max_length: "Zpráva nemůže být delší než 500 znaků."
  },

  store_settings: {
    title: "Nastavení obchodu",
    delete_store: "Smazat obchod",
    delete_store_message: `
      Tato akce trvale smaže obchod: <b>{store_name}</b> dne <b>{date}</b>, včetně všech jeho produktů sdílených prostřednictvím tržiště Ulsemo nebo služby křížového prodeje Ulsemo.
      Stejně tak jako všechny údaje o objednávkách, fakturách a jakýchkoli dalších dat uložených v tomto obchodě.
    `,
    deletion_steps: "Chcete-li obchod smazat, nejprve potvrďte tuto akci vepsáním názvu obchodu tak, jak je uvedeno zde:",
    deletion_label: "Název obchodu",
    remove_user: "Vymazat také permanentně můj účet.",
    remove_user_message: "Vymažte váš účet permanentně z Ulsema a všech obchodů, ke kterým jste přiřazeni.",
    remove_user_tooltip: "Možné pouze v případě, že nejste vlastníkem žádného jiného obchodu."
  },

  billing: {
    title: "Předplatné a vyúčtování",
    title_before_payment:
      "Poslední krok - Odemkněte svůj internetový obchod!",
    message_before_payment: `<b>Gratulujeme k úspěšnému nastavení Vašeho internetového obchodu! Jste jen krůček od spuštění svého nového podnikání!</b><br/><br/>
    Dokončením tohoto posledního kroku a zadáním platebních údajů zajistíte bezproblémový chod a růst vašeho obchodu. Navíc získáte okamžitý přístup ke svému krásně navrženému obchodu, který je připraven předvést vaše produkty a okouzlit vaše zákazníky.<br/><br/>
    <b>Nebojte se, nic neplatíte, než skončí Vaše 10denní zkušební lhůta</b>, a kdykoli můžete aktualizovat své platební údaje nebo předplatné zrušit.<br/><br/>
    Připravte se na vzrušující cestu podnikání a úspěchu na internetu! Jsme nadšeni, že můžeme být součástí Vaší cesty a nemůžeme se dočkat, až uvidíme, jak váš obchod prosperuje!<br/><br/>`,
    payment_method_title: "Předplatné Ulsemo",
    payment_method_message: "Zde si můžete nastavit a spravovat předplatné a zároveň prohlížet a stahovat veškeré faktury za služby Ulsemo.",
    payment_method_button_add: "Nastavit a zaplatit předplatné",
    payment_method_button_change: "Vstoupit do platebního portálu Ulsemo",
    subscription_message: `Pokud si přejete nastavit předplatné před koncem zkušební verze, můžete tak samozřejmně učinit.<br/>
    Předplatné začne platit až po skončení zkušební verze, takže Vám do té doby nebude nic účtováno.`,
    statuses: {
      subscribed: "Předplatné je aktivní.",
      unsubscribed: "Předplatné není aktivní.",
      created: "Vaše předplatné bylo úspěšně nastaveno!",
      canceled: "Vaše předplatné bylo úspěšně zrušeno.",
      error: "Při nastavování předplatného došlo k chybě, zkuste to prosím znovu."
    },
    submit_button: "Odemknout Můj Obchod",
    back_button: "Zpět do administrace",
  }
}
