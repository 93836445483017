export default {
  title: "Produkty",
  main_message: `<h3>Produktová kategorizace používá následující strukturu: <i>Nadřazená kolekce</i> -> <i>Kolekce</i> -> <i>Produkt</i>.
    <b>Příklad: Oblečení -> Bundy -> Džínová bunda.</b>
    <br/>Pro vytvoření <i>Nadřazené kolekce</i> musíte nejdříve vytvořit <i>Kolekci</i>. <i>Nadřazená kolekce</i>
    nemůže být vytvořena bez přiřazení alespoň jedné <i>Kolekce</i>.
    <br/><b><i>Kolekce</i> může být přiřazena pouze k jedné <i>Nadřazená kolekci</i></b></h3>`,
  choose_collection: "Vybrat kolekci:",
  products: "produkty",
  product: "produkt",
  product_upper: "Product",
  options: "Kategorie variant",
  option: "Kategorie varianty",
  description: "Popis",
  message: "Info o produktu",
  price: "Cena",
  purchase_price: "Vynaložené náklady za jednotku",
  purchase_price_message: "Celková částka, kterou jste za jednotku tohoto produktu či služby vynaložily.",
  compare_at_price: "Cena před slevou",
  weight: "Váha",
  length: "Délka",
  height: "Výška",
  width: "Šířka",
  size: "Velikost",
  quantity: "Celkové množství",
  sku: "SKU",
  inventory: "Množství",
  available: "Dostupný",
  product_name: "Název produktu",
  value: "Hodnota",
  image: "Obrázek",
  edit_delete_number_of_products: "Upravit nebo smazat {count} variant",
  should_product_be_available: "Produkt je dostupný v obchodě",
  add_product: "Přidat nový produkt",
  product_unavailable: "Produkt není dostupný",
  empty_shopify_message: `Pokud jste připojili svůj účet Shopify, ale nevidíte žádné produkty,
  ujistěte se, že všechny poskytnuté informace v konfiguraci připojení Shopify jsou správné.`,
  go_to_shopify_config: "Přejít do konfigurace Shopify",
  total_sold: "Celkový počet prodaných produktů",
  total_sold_message: "Celkový počet prodaných produktů od vzniku obchodu",
  total_revenue: "Celkový obrat",
  total_revenue_message: "Celkový obrat z produktů ve vybrané měně od vzniku obchodu",
  product_dimensions: "Rozměry",
  product_dimensions_message: `Tyto rozměry se používají k výpočtu nejlepších možných přepravních sazeb pro vaše zákazníky.
  Pokud se jednotlivé varianty produktu liší rozměry nebo hmotností, můžete tyto rozměry nastavit u každé jednotlivé varianty.`,
  product_categorization: "Kategorizace",
  product_tags: "Štítky",
  product_tags_message: `Štítky produktů umožňují zákazníkům snadno vyhledávat vaše produkty na
  tržišti Ulsemo, také umožňují spárování podobných produktů ve vašem obchodě.`,
  is_physical_product: "Jedná se o fyzický produkt",
  variant_dimensions: "Rozměry varianty",
  marketplace: "Přidání na tržiště Ulsemo",
  marketplace_checkbox: "Přidat na tržiště",
  marketplace_message: "Umožňuje zákazníkům prohlížet a kupovat Váš produkt přímo z tržiště Ulsemo.",
  selected_language: "Vybrat řeč",
  imported_title: "Moje produkty",
  shopify_title: "Vaše Shopify produkty",
  products_with_errors: "Produkty s chybami:",
  required_fields: "* povinná pole",
  product_message_info: "Info o produktu slouží k upoutání pozornosti uživatelů a vyvolání zájmu o produkt.",
  product_description_info: "Popis produktu by měl obsahovat podrobné popsání produktu a veškeré jeho parametry a vlastnosti.",
  favorite_product_message: "Produkty označené jako 'favorit' mají prioritu při výběru do seznamů produktů v menu v rámci položek kolekce.",
  collection_has_no_available_products: "Tato kolekce nemá žádné dostupné produkty.",

  cross_sale: {
    title: "Ulsemo cross-selling",
    checkbox: "Umožnit přeprodej produktu",
    message: `Umožňuje ostatním prodejcům v rámci Ulsemo ekosystému nabízet tento produktu za podmínek, které zde nastavíte.
    Budete tak oba benefitovat z prodeje tohoto produktu zkrz jejich online obchod.<br>
    Čím vyšší procento nastavíte, tím vyšší incentiva pro ostatní prodejce nabízet Váš produkt.`,
    percentage: "Procentní podíl na prodeji produktu",
    percentage_message: "Kolik procent z ceny produktu/varianty bude náležet prodejci, který Váš produkt bude přeprodávat.",
    percentage_calculation: "Kolik na tomto produktu/variantě vyděláte v případě přeprodeje:",
    create_button: "Přidat produkt od jiných prodejců na Ulsemu",
    search_by_category: "Vyhledat podle kategorie",
    search_by_parameters: "Vyhledat podle jména a parametrů",
    search_by_parameters_placeholder: "🔍   Vyhledat podle jména a parametrů",
    found_products: "Nalezené produkty",
    search_again: "Znovu vyhledávat",
    no_products: "Žádné produkty nenalezeny",
    country_of_origin: "Země původu",
    resale_percentage: "Procenta z prodeje produktu",
    price: "Cena produktu nebo první varianty",
    is_cross_sold: "Jedná se o přeprodávaný produkt",
    currency_title: "Konverzní měna",
    currency_message: "Měna, která se použije pro výpočet ceny v případě potřeby přepočtu měny.",
    label: "Měna",
    add_cross_product: "Přidat produkt do obchodu",
    preview_cross_product: "Prohlédnout detail produktu",
    approximate_profit: "Přibližný zisk z prodeje tohoto produktu nebo první varianty",
    profit_on_product: "Kolik procent z prodeje produktu/varianty získáte.",
    already_in_store: "Tento produkt je již ve Vašem obchodě.",
    owner_made_unavailable: "Majitel tohoto produktu dočasně pozastavil jeho prodej."
  },

  currency_change: {
    first: "Pro změnu měny přejděte do modulu",
    second: "a změňte měnu pro vybranou řeč."
  },

  add_to: {
    popular: "Přidat produkt do Oblíbených produktů",
    highlighted: "Přidat produkt do Zvýrazněných produktů"
  },

  remove_from: {
    popular: "Odebrat produkt z Oblíbených produktů",
    highlighted: "Odebrat produkt z Zvýrazněných produktů"
  },

  inputs: {
    weight_unit: "Jednotka váhy",
    distance_unit: "Jednotka vzdálenosti",
  },

  collections: {
    title: "Kolekce",
    add: "Přidat novou kolekci",
    edit: "Upravit kolekci",
    name: "Název kolekce",
    explanation: "Kolekce jsou konkrétní skupiny produktů, jako jsou Bundy v Oblečení nebo Citrusy v Ovoci",
    collection: "Kolekce",
    available_collections: "Dostupné kolekce",
    add_collection_products: "Připojit vytvořené produkty ke kolekci",
    add_parent_collection_collections: "Připojit vytvořené kolekce k nadřazené",
  },

  parent_collections: {
    title: "Nadřazené kolekce:",
    add: "Přidat nadřazenou kolekci",
    edit: "Upravit nadřazenou kolekci",
    name: "Název nadřazené kolekce",
    explanation: "Nadřazená kolekce je například: Oblečení pro Bundy nebo Maso pro Vepřové",
    without_parent: "Bez nadřazené kolekce",
    without_collection: "Bez kolekce",
    parent: "Nadřazená",
    no_collection_message: "Nejdříve musíte vytvořit kolekci aby jste umožnil tvorbu nadřazené kolekce."
  },

  categories: {
    title: "Výběr kategorie produktu",
    base_categories: "Základní kategorie",
    open_categories_modal: "Vybrat kategorii",
    change_categories_modal: "Změnit kategorii",
    shopify_message: `Shopify produkty není možné nabízet na tržišti Ulsemo, prosím převeďte produkty na Ulsemo produkty.`,
    search: "🔍   Prohledejte kategorie"
  },

  variants: {
    title: "Varianty",
    add_option_placeholder: "Barva, Velikost, Materiál, atd...",
    message: "Varianty produktu mohou mít jakoukoliv číselnou, textovou nebo HEX (CSS barva) hodnotu.",
    variant: "Varianta",
    add_new_variant: "Přidat novou variantu",
    edit_selected: "Upravit vybrané",
    done_editing: "Konec úprav",
    pricing_and_inventory: "Varianty a ceny produktu",
    choose_variant_image: "Vybrat obrázek varianty",
    add_new_variant_option: "Přidat další kategorii",
    edit_variant_options: "Upravit kategorie variant",
    edit_variant_dimensions: "Upravit rozměry varianty",
    price_history: "Historie změn ceny",
    price_history_language_data_missing: "Pro vybranou řeč neexistuje historie změn ceny.",
    price_history_data_unchanged: "Cena zatím nebyla změněna od vytvoření produktu/varianty."
  },

  images: {
    title: "Obrázky",
    image: "Obrázek",
    preview: "náhled",
    edit: "upravit",
    add_new_image: "Přidat nový obrázek",
    preview_image: "Náhled obrázku",
    edit_image: "Upravit obrázek",
    edit_image_alt_text: "Upravit Alt text obrázku",
    change_image: "Změnit obrázek",
    delete_image: "Smazat obrázek",
    alt_text: "Alt text",
    image_description: "Popis obrázku",
    upload_image: "Nahrát obrázek",
    add_collection_image: "Přidat obrázek kolekce",
    upload_images: "Nahrát obrázek/ky",
    edit_images: "Upravit obrázek",
    preview_images: "Náhled obrázku",
    message: `V této části můžete nahrát obrázky svých produktů,
    přidejte k nim i Alt text, který pomáhá s SEO Vašeho obchodu.
    Můžete také změnit pořadí pouhým přetažením do požadovaného umístění.
    Pokud plánujete přidat tento produkt na stránku Mikrosite,
    doporučujeme, aby první 3 obrázky byly obrázky s průhledným pozadím (formát PNG),
    získate tím nejlepší možný vizuální efekt.`,
    alt_text_explanation: "Alt (alternativní) text popisuje obsah obrázků a je důležitou součástí popisu produktu. Pokud se obrázek z nějakého důvodu nemůže načíst, <br>zobrazí se místo něj alternativní text. Vložení alt textu může zlepšit SEO hodnocení Vašeho eshopu."
  },

  purchase_price_currency: {
    title: "Měna vynaložených nákladů",
    placeholder: "Vyberte měnu",
    message: `Měna vynaložených nákladů je měna, kterou jste platili částky vynaložené na všechny náklady souvisejících s nákupem nebo prodejem Vašeho produktu/varianty, jako jsou náklady na výrobu, marketing, dopravu nebo jakékoli jiné náklady.
    Jejím definováním a přiřazením hodnoty každému produktu/variantě můžeme lépe odhadnout čistý zisk Vašeho obchodu.`
  },

  csv_upload: {
    title: "Přidejte své produkty rychleji nahráním souboru CSV!",
    message: "Níže nahrajte svůj soubor CSV. Ujistěte se, že formát souboru CSV je správný a v souladu s naší CSV šablonou.",
    template_download: `Stáhněte si a dodržujte datovou strukturu znázorněnou v naší <a href="/static/others/Ulsemo-product-upload-template.csv" ke stažení>CSV šabloně</a>. Nebo si můžete stáhnout naši prázdnou šablonu bez jakékoli demo hodnoty zde <a href="/static/others/Ulsemo-product-upload-empty-template.csv" download>Prázdná CSV šablona</a>`,
    tooltip: "Nahrát produkty pomocí CSV dokumentu",
    table_title: "Vysvětlivky k CSV sloupcům",
    column: "Název sloupce",
    explanation: "Vysvětlení",
    drag_and_drop_csv: "Přetáhněte CSV soubor nebo sem klikněte a vyberte.",
    fields: {
      parent_collection: "<b>Parent Collection</b> - Název nadřazené kolekce tak, jak ji chcete zobrazovat v obchodě. Například: Oblečení",
      collection: "<b>Collection</b> - Název kolekce tak, jak chcete, aby byla zobrazena v obchodě. Například: Trička",
      product_title: `<b>Product title [KÓD JAZYKA]</b> – Název přidávaného produktu. Můžete také nastavit název pro každý dostupný jazyk Vašeho obchodu.
      Stačí přidat/aktualizovat název sloupce tak, aby obsahoval kód jazyka používaného Vaším obchodem. Jako příklad se podívejte na naši šablonu CSV, tam přidáváme název produktu pro angličtinu (EN) a češtinu (CZ).<br>
      Pokud není přidán žádný jazyk, bude název použit pro všechny dostupné jazyky. Dostupné jazykové kódy jsou: EN, DE, ES, CZ, SK, SR, HR, PL, PT, CH, IND, JP, RU, VN`,
      is_product_available: "<b>Is product available</b> – Tento sloupec nastavuje dostupnost produktu ve Vašem obchodě. V podstatě říká, zda jej uživatelé mohou vidět nebo ne. Přijatelné hodnoty jsou: TRUE nebo FALSE",
      tags: "<b>Tags</b> - Tagy se používají k lepšímu propojení podobných produktů a pomáhají při SEO vyhledávání. Hodnoty musí být odděleny čárkou, například: bundy,oblečení,kožené bundy",
      images: "<b>Images</b> – URL adresy obrázků Vašich produktů. Pokud je přidáno více adres URL, musí být odděleny čárkou, například: https://my-images.com/my-cool-image-1.png, https://my-images.com/my-cool-image-2.png",
      image_alt_texts: `<b>Image Alt Texts</b> – Alternativní texty pomáhají Vašim obrázkům dosáhnout vyšší pozice ve vyhledávání SEO. Hodnoty musí odpovídat pořadí URL adres obrázků, takže první alternativní text bude přidán k první adrese URL obrázku a tak dále.
      Pokud chcete, aby všechny obrázky měly stejný text Alt, stačí přidat jednu hodnotu a my ji přidáme ke všem.
      <br> Hodnoty musí být odděleny čárkou, například: Bunda s pruhy zepředu, Bunda s pruhy zezadu, Bunda s pruhy na ze strany`,
      message: "<b>Message [KÓD JAZYKA]</b> – Info o produktu, který přidáváte. Tato hodnota může být také v podobě HTML kódu, ale nesmí obsahovat žádné elementy <script>. Můžete také nastavit hodnotu Infa pro každý dostupný jazyk Vašeho obchodu. Funguje to stejně jako u 'Product title'.",
      description: "<b>Description [KÓD JAZYKA]</b> – Popis produktu, který přidáváte. Tato hodnota může být také v podobě HTML kódu, ale nesmí obsahovat žádné elementy <script>. Můžete také nastavit hodnotu POpisu pro každý dostupný jazyk Vašeho obchodu. Funguje to stejně jako u 'Product title'.",
      categorization: `<b>Categorization</b> – Kategorie Vám umožňují určit, jak bychom měli kategorizovat Vaše produkty na tržišti Ulsemo, pokud tam chcete také prodávat. Navíc nám umožňují lépe analyzovat výkon Vašich produktů v celém ekosystému Ulsemo.
      <br> Musíte použít náš systém kategorizace, což znamená ID kategorií určená společností Ulsemo. Najdete je v našem <a href="/static/others/Ulsemo-categorization.csv" download>Kategorizačním CSV</a>. Například pro kategorii Shirts & Tops, by byla hodnota: id5cd8cc58d109d, ide8091d4f4df2f, idac99bd721e6ef`,
      place_on_marketplace: "<b>Place On Marketplace</b> - Umožňuje Vám prodávat své produkty prostřednictvím tržiště Ulsemo. Chcete-li tuto možnost aktivovat, musíte také vyplnit kategorii produktů. Přijatelné hodnoty jsou: TRUE nebo FALSE",
      cost_price_currency: "<b>Cost Price Currency</b> – určuje, jakou měnu bychom měli použít při výpočtu všech nákladů souvisejících s Vaším produktem. Dostupné měny jsou: AUD, BRL, CAD, CNY, CZK, DKK, EUR, HKD , HUF, INR, ILS, JPY, MYR, MXN, TWD, NZD, NOK, PHP, PLN, GBP, RUB, SGD, SEK, CHF, THB, USD",
      dimensions: `<b>Dimensions</b> – Dimenze se používají k tomu, aby bylo možné lépe vypočítat sazby za dopravu pro Vaše uživatele. Ve sloupci 'Dimensions' můžete nastavit globální rozměry aplikovatelné na každou variantu produktu.
      <br>Varianty je třeba nastavit v následujícím formátu: délka, šířka, výška, hmotnost. Pro každý rozměr je třeba nastavit měrnou jednotku. Dostupné jednotky jsou pro Velikost: m, cm, mm, yd, ft, in a pro Hmotnost: kg, g, lb, oz.
      <br>Jednotky NELZE kombinovat, tzn. nemůžete napsat: 6ft3in, ale musíte napsat: 6,25ft. Kromě toho nemůžete také zaměňovat jednotky, pokud zvolíte jednotku pro Velikost 'cm', pak všechny rozměry Velikosti pro tento produkt musí používat tuto jednotku.
      <br>Příklad správného zápisu by byl: 50cm, 50cm, 100cm, 2,5kg`,
      variant_option_names: `<b>Variant Option Names [JAZYKOVÝ KÓD]</b> – Tyto názvy popisují druhy variant produktu. Například pro trička by to byly druhy variant: Velikosti nebo Barva.
      <br>Můžete nastavit na 3 z těchto možností a je třeba je oddělit čárkou, například: Velikosti, Barvy`,
      variant_option_values: `<b>Variant Option Values [POZICE DRUHU] [KÓD JAZYKA]</b> – Toto jsou hodnoty pro každý druh varianty, kterou jste nastavili ve sloupci 'Variant Option Names'.
      <br> Musíte nastavit 'POZICE DRUHU', což představuje pořadí druhů Vašich variant, jak jste je nastavili v 'Variant Option Names', takže pro první druh varianty byste dali 1, pro druhý 2 a třetí 3.
      <br> Hodnoty specifické pro jazyk můžete opět přidat přidáním jazykového kódu. Takže příklad názvu sloupce by byl: 'Variant Option Values ​​1 EN' a hodnoty pro každou variantu pod tímto názvem možnosti mohou být: Malá, Střední, Velká`,
      variant_prices: "<b>Variant Prices [JAZYKOVÝ KÓD]</b> – Zde můžete nastavit ceny pro každou variantu a jazyk (pro měnu specifikovanou pro daný jazyk). Například název sloupce bude: 'Variant Prices EN' a hodnoty by byly: 300, 500, 1000",
      variant_prices_before_discount: "<b>Variant Prices Before Discount [KÓD JAZYKA]</b> – Zde můžete nastavit ceny před slevou (pro produkty ve slevě) pro každou variantu a jazyk (pro měnu specifikovanou pro daný jazyk). Název sloupce by byl například: 'Variant Prices Before Discount EN' a hodnoty by byly: 500, 700, 1500",
      variant_skus: "<b>Variant SKUs</b> – Zde můžete nastavit SKU pro každou variantu oddělené čárkou. Například: A1B2011, A1B2012, A1B2013",
      variant_total_inventory: "<b>Total Variant Inventory</b> – Umožňuje nastavit množství zásob na skladě pro každou variantu, musí být oddělené čárkou, například: 500, 380, 425",
      variant_purchase_prices: "<b>Variant Purchase Prices</b> – Umožňují nastavit nákupní cenu pro každou variantu, musí být oddělené čárkou, například: 220, 350, 670",
      variant_dimensions: `<b>Variant Dimensions</b> – Stejně jako u sloupce 'Dimensions' Vám ​​tento sloupec umožňuje nastavit rozměry pro každou variantu, ovšem zvlášť, například v případě, že se rozměry každé varainty výrazně liší.
      <br>Platí stejná pravidla jako pro sloupec 'Dimensions', zde však musíte každou sadu hodnot variant rozměrů zabalit do závorek, například: (50cm, 80cm, 5cm, 500g), (60cm, 80cm, 5cm , 700g), (70cm, 80cm, 5cm, 800g)`,
    }
  }
}
